import './admin.scss';

const acf = window['acf'];

const blocks = [
	{
		name: 'cardgrid',
		radio: 'field_673212aa3c5c3',
		preview: 'field_6756ec4e11275',
	},
	{
		name: 'image-and-text',
		radio: 'field_672ccb9a9bb02',
		preview: 'field_6756eed356b14',
	},
	{
		name: 'heading-and-text',
		radio: 'field_6731f9f92ca92',
		preview: 'field_6756e9f7b1f5f',
	},
];

/* Set preview and add listener for all radio fields on startup */
window.onload = () => {
	blocks.forEach((block) => {
		const radioFields = acf ? acf.getFields({ key: block.radio }) : [];

		radioFields.forEach((radioField: any) => {
			addPreviewListener(radioField, block.name, block.preview);
		})
	})
};

/* Listen for new blocks being added to the page, and set those up also */
if (acf) acf.addAction('append', function($el) {
	if ($el.hasClass('acf-block-fields')) {
		blocks.forEach((block) => {
			const radioField = acf.getFields({ key: block.radio, parent: $el });
			if (radioField) {
				addPreviewListener(radioField[0], block.name, block.preview);
			}
		});
	}
});

/* Add the listener to populate the preview on change of the radio */
const addPreviewListener = (radioField: any, blockName: String, blockPreview: String) => {

	if (radioField) {
		const radioFieldEl = radioField.$el[0];
		const previewField = radioFieldEl.parentElement.querySelector(`[data-key="${blockPreview}"] .acf-input`);

		if (previewField) {
			previewField.innerHTML = `<img src="/wp-content/plugins/hhh-teravalis-blocks/public/assets/images/blocks/${blockName}/layouts/${radioField.val().toLowerCase()}.png"/>`;
			radioFieldEl.addEventListener('change', () => {
				previewField.innerHTML = `<img src="/wp-content/plugins/hhh-teravalis-blocks/public/assets/images/blocks/${blockName}/layouts/${radioField.val().toLowerCase()}.png"/>`;
			});
		}
	}

};
